var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"jobInfo"},[_vm._m(0),_c('div',{staticClass:"infoBox"},[_c('el-form',{attrs:{"label-width":"96px"}},[_c('el-form-item',{attrs:{"label":"职业性质","rules":[
            {
              required: true,
              message: '请选择职业性质',
              trigger: 'change',
            } ]}},[_c('el-radio',{attrs:{"label":"1","disabled":_vm.qzdisable},on:{"change":function($event){(_vm.jzShow = true), (_vm.qzShow = false)}},model:{value:(_vm.flag),callback:function ($$v) {_vm.flag=$$v},expression:"flag"}},[_vm._v("兼职")]),_c('el-radio',{attrs:{"label":"2","disabled":_vm.jzdisable},on:{"change":function($event){(_vm.qzShow = true), (_vm.jzShow = false)}},model:{value:(_vm.flag),callback:function ($$v) {_vm.flag=$$v},expression:"flag"}},[_vm._v("全职")])],1)],1),(_vm.jzShow)?_c('div',[_c('el-form',{attrs:{"label-width":"96px","model":_vm.jzpostInfo}},[_c('el-form-item',{staticStyle:{"position":"relative"},attrs:{"label":"需求证书","prop":"j_register","rules":[
              {
                required: true,
                message: '请选择需求证书和注册情况',
                trigger: 'change',
              } ]}},[_c('el-cascader',{staticStyle:{"width":"70%"},attrs:{"placeholder":"选择或搜索需求证书","disabled":_vm.jzdisable,"options":_vm.selCerList,"props":{
                checkStrictly: true,
                value: 'certificate',
                label: 'certificate',
                children: 'child',
              },"filterable":"","clearable":""},model:{value:(_vm.cerList),callback:function ($$v) {_vm.cerList=$$v},expression:"cerList"}}),_c('el-select',{staticStyle:{"width":"30%"},attrs:{"placeholder":"注册情况"},model:{value:(_vm.jzpostInfo.j_register),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_register", $$v)},expression:"jzpostInfo.j_register"}},[_c('el-option',{attrs:{"label":"初始注册","value":"初始注册"}}),_c('el-option',{attrs:{"label":"转注册","value":"转注册"}}),_c('el-option',{attrs:{"label":"初转不限","value":"初转不限"}})],1)],1),_c('el-form-item',{attrs:{"label":"证书价格","prop":"j_min_price","rules":[
              {
                required: true,
                message: '请填写证书最小价格和最大价格',
                trigger: 'blur',
              } ]}},[_c('el-input-number',{staticStyle:{"width":"48%"},attrs:{"min":0,"controls":false,"placeholder":"证书最小价格（单位：万元）"},on:{"change":_vm.getPrice},model:{value:(_vm.jzpostInfo.j_min_price),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_min_price", $$v)},expression:"jzpostInfo.j_min_price"}}),_c('span',{staticStyle:{"color":"#999"}},[_vm._v(" — ")]),_c('el-input-number',{staticStyle:{"width":"48%"},attrs:{"min":0,"controls":false,"placeholder":"证书最大价格（单位：万元）"},on:{"change":_vm.getPrice},model:{value:(_vm.jzpostInfo.j_max_price),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_max_price", $$v)},expression:"jzpostInfo.j_max_price"}})],1),_c('el-form-item',{attrs:{"label":"用证地区","rules":[
              {
                required: true,
              } ]}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"size":"large","options":_vm.comcity,"placeholder":_vm.cityzwf},on:{"change":_vm.setjzcity},model:{value:(_vm.jzcity),callback:function ($$v) {_vm.jzcity=$$v},expression:"jzcity"}})],1),_c('el-form-item',{attrs:{"label":"证书用途","prop":"j_purpose","rules":[
              {
                required: true,
                message: '请选择证书用途',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_purpose),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_purpose", $$v)},expression:"jzpostInfo.j_purpose"}},[_c('el-option',{attrs:{"label":"用于资质","value":"用于资质"}}),_c('el-option',{attrs:{"label":"用于项目","value":"用于项目"}})],1)],1),_c('el-form-item',{attrs:{"label":"社保要求","prop":"j_social_need","rules":[
              {
                required: true,
                message: '请选择社保要求',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_social_need),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_social_need", $$v)},expression:"jzpostInfo.j_social_need"}},[_c('el-option',{attrs:{"label":"不限","value":"不限"}}),_c('el-option',{attrs:{"label":"唯一社保","value":"唯一社保"}}),_c('el-option',{attrs:{"label":"非唯一社保","value":"非唯一社保"}})],1)],1),_c('el-form-item',{attrs:{"label":"签约时长","prop":"j_sign_time","rules":[
              {
                required: true,
                message: '请选择签约时长',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_sign_time),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_sign_time", $$v)},expression:"jzpostInfo.j_sign_time"}},[_c('el-option',{attrs:{"label":"1个月","value":"1个月"}}),_c('el-option',{attrs:{"label":"2个月","value":"2个月"}}),_c('el-option',{attrs:{"label":"3个月","value":"3个月"}}),_c('el-option',{attrs:{"label":"4个月","value":"4个月"}}),_c('el-option',{attrs:{"label":"5个月","value":"5个月"}}),_c('el-option',{attrs:{"label":"6个月","value":"6个月"}}),_c('el-option',{attrs:{"label":"7个月","value":"7个月"}}),_c('el-option',{attrs:{"label":"8个月","value":"8个月"}}),_c('el-option',{attrs:{"label":"9个月","value":"9个月"}}),_c('el-option',{attrs:{"label":"10个月","value":"10个月"}}),_c('el-option',{attrs:{"label":"11个月","value":"11个月"}}),_c('el-option',{attrs:{"label":"12个月","value":"12个月"}}),_c('el-option',{attrs:{"label":"2年","value":"2年"}}),_c('el-option',{attrs:{"label":"3年","value":"3年"}})],1)],1),_c('el-form-item',{attrs:{"label":"签约方式","prop":"j_sign_method","rules":[
              {
                required: true,
                message: '请选择签约方式',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_sign_method),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_sign_method", $$v)},expression:"jzpostInfo.j_sign_method"}},[_c('el-option',{attrs:{"label":"企业直签","value":"企业直签"}}),_c('el-option',{attrs:{"label":"委托代签","value":"委托代签"}})],1)],1),_c('el-form-item',{attrs:{"label":"付款方式","prop":"j_pay_method","rules":[
              {
                required: true,
                message: '请选择签约方式',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_pay_method),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_pay_method", $$v)},expression:"jzpostInfo.j_pay_method"}},[_c('el-option',{attrs:{"label":"定金加尾款","value":"定金加尾款"}}),_c('el-option',{attrs:{"label":"见证全款","value":"见证全款"}}),_c('el-option',{attrs:{"label":"公示全款","value":"公示全款"}}),_c('el-option',{attrs:{"label":"提交全款","value":"提交全款"}}),_c('el-option',{attrs:{"label":"转网上关系全款","value":"转网上关系全款"}})],1)],1),_c('el-form-item',{attrs:{"label":"签约状态","prop":"j_sign_status","rules":[
              {
                required: true,
                message: '请选择签约方式',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.jzpostInfo.j_sign_status),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_sign_status", $$v)},expression:"jzpostInfo.j_sign_status"}},[_c('el-option',{attrs:{"label":"洽谈中","value":"洽谈中"}}),_c('el-option',{attrs:{"label":"已签约","value":"已签约"}})],1)],1),_c('el-form-item',{attrs:{"label":"职位描述","prop":"j_job_agree","rules":[
              {
                required: true,
                message: '请输入职位描述',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":3,"maxlength":"1000"},on:{"input":_vm.infoInput1},model:{value:(_vm.jzpostInfo.j_job_agree),callback:function ($$v) {_vm.$set(_vm.jzpostInfo, "j_job_agree", $$v)},expression:"jzpostInfo.j_job_agree"}}),_c('p',{staticClass:"text-right padding-top-5"},[_vm._v(" 您还可以输入"),_c('span',{staticClass:"font-blue"},[_vm._v(_vm._s(_vm.destextLength1))]),_vm._v(" 字 ")])],1)],1)],1):_vm._e(),(_vm.qzShow)?_c('div',[_c('el-form',{attrs:{"label-width":"96px","model":_vm.postInfo}},[_c('el-form-item',{staticStyle:{"position":"relative"},attrs:{"label":"需求证书","prop":"j_register","rules":[
              {
                required: true,
                message: '请选择需求证书和注册情况',
                trigger: 'change',
              } ]}},[_c('el-cascader',{staticStyle:{"width":"70%"},attrs:{"placeholder":"选择或搜索需求证书","disabled":_vm.qzdisable,"options":_vm.selCerList,"props":{
                checkStrictly: true,
                value: 'certificate',
                label: 'certificate',
                children: 'child',
              },"filterable":"","clearable":""},model:{value:(_vm.cerList),callback:function ($$v) {_vm.cerList=$$v},expression:"cerList"}}),_c('el-select',{staticStyle:{"width":"30%"},attrs:{"placeholder":"注册情况"},model:{value:(_vm.postInfo.j_register),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_register", $$v)},expression:"postInfo.j_register"}},[_c('el-option',{attrs:{"label":"初始注册","value":"初始注册"}}),_c('el-option',{attrs:{"label":"转注册","value":"转注册"}}),_c('el-option',{attrs:{"label":"初转不限","value":"初转不限"}})],1)],1),_c('el-form-item',{staticClass:"requireLabel",attrs:{"label":"职位类型"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.selJobList,"props":{
                checkStrictly: true,
                value: 'category_name',
                label: 'category_name',
                children: 'child',
              },"filterable":"","clearable":""},model:{value:(_vm.jobList),callback:function ($$v) {_vm.jobList=$$v},expression:"jobList"}}),_c('el-checkbox',{staticClass:"margin-left-20",model:{value:(_vm.tjzw),callback:function ($$v) {_vm.tjzw=$$v},expression:"tjzw"}},[_vm._v("选择系统推荐的职位")])],1),_c('el-form-item',{attrs:{"label":"月薪","prop":"j_max_price","rules":[
              {
                required: true,
                message: '请填写最低月薪和最高月薪',
                trigger: 'blur',
              } ]}},[_c('el-input',{staticStyle:{"width":"48%"},attrs:{"placeholder":"最低月薪（单位：千元）"},model:{value:(_vm.postInfo.j_min_price),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_min_price", _vm._n($$v))},expression:"postInfo.j_min_price"}}),_c('span',{staticStyle:{"color":"#999"}},[_vm._v(" — ")]),_c('el-input',{staticStyle:{"width":"48%"},attrs:{"placeholder":"最高月薪（单位：千元）"},model:{value:(_vm.postInfo.j_max_price),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_max_price", _vm._n($$v))},expression:"postInfo.j_max_price"}})],1),_c('el-form-item',{attrs:{"label":"职位名称","prop":"j_job_name","rules":[
              {
                required: true,
                message: '请填写职位名称',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"minlength":"2","placeholder":"请填写职位名称，2~30个字符"},model:{value:(_vm.postInfo.j_job_name),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_job_name", $$v)},expression:"postInfo.j_job_name"}}),_c('span',{staticStyle:{"font-size":"12px","color":"#f00","line-height":"16px","display":"inline-block"}},[_vm._v(" （重要提示：根据相关法律法规，我们将对您提交的职位进行审核，通常不超过30分钟，职位名称中含有“挂资质”\"社保\"“挂项目”“挂靠”“挂证”“聘证”“寻证”等关键词禁止发布，通过后将自动发布） ")])],1),_c('el-form-item',{attrs:{"label":"所在地区","prop":"j_work_place","rules":[
              {
                required: true,
                message: '请选择所在地区和详细街道门牌号',
                trigger: 'change',
              } ]}},[_c('el-cascader',{staticStyle:{"width":"48%"},attrs:{"size":"large","options":_vm.comcity,"placeholder":_vm.cityzwf},on:{"change":_vm.setUseInfoSocial},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}}),_c('span',{staticStyle:{"color":"#999"}},[_vm._v(" — ")]),_c('el-input',{staticStyle:{"width":"48%"},attrs:{"placeholder":"街道门牌号"},model:{value:(_vm.postInfo.j_work_place),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_work_place", $$v)},expression:"postInfo.j_work_place"}})],1),_c('el-form-item',{attrs:{"label":"职称类型","prop":"j_job_need","rules":[
              {
                required: true,
                message: '请选择职称类型和职称等级',
                trigger: 'change',
              } ]}},[_c('el-cascader',{staticStyle:{"width":"70%"},attrs:{"placeholder":"选择或搜索职称类型","options":_vm.selPostList,"props":{
                checkStrictly: true,
                value: 'title',
                label: 'title',
                children: 'child',
              },"filterable":"","clearable":""},model:{value:(_vm.postList),callback:function ($$v) {_vm.postList=$$v},expression:"postList"}}),_c('el-select',{staticStyle:{"width":"30%"},attrs:{"placeholder":"职称等级"},model:{value:(_vm.postInfo.j_job_need),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_job_need", $$v)},expression:"postInfo.j_job_need"}},[_c('el-option',{attrs:{"label":"不限","value":"不限"}}),_c('el-option',{attrs:{"label":"初级","value":"初级"}}),_c('el-option',{attrs:{"label":"中级","value":"中级"}}),_c('el-option',{attrs:{"label":"高级","value":"高级"}})],1)],1),_c('el-form-item',{attrs:{"label":"工作经验","prop":"j_work_experience","rules":[
              {
                required: true,
                message: '请选择工作经验',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.postInfo.j_work_experience),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_work_experience", $$v)},expression:"postInfo.j_work_experience"}},[_c('el-option',{attrs:{"label":"1年内","value":"1年内"}}),_c('el-option',{attrs:{"label":"1-3年","value":"1-3年"}}),_c('el-option',{attrs:{"label":"3-5年","value":"3-5年"}}),_c('el-option',{attrs:{"label":"5-8年","value":"5-8年"}}),_c('el-option',{attrs:{"label":"8-10年","value":"8-10年"}}),_c('el-option',{attrs:{"label":"10年以上","value":"10年以上"}})],1)],1),_c('el-form-item',{attrs:{"label":"学历要求","prop":"j_education_need","rules":[
              {
                required: true,
                message: '请选择工作经验',
                trigger: 'change',
              } ]}},[_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.postInfo.j_education_need),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_education_need", $$v)},expression:"postInfo.j_education_need"}},[_c('el-option',{attrs:{"label":"高中","value":"高中"}}),_c('el-option',{attrs:{"label":"中技","value":"中技"}}),_c('el-option',{attrs:{"label":"中专","value":"中专"}}),_c('el-option',{attrs:{"label":"大专","value":"大专"}}),_c('el-option',{attrs:{"label":"本科","value":"本科"}}),_c('el-option',{attrs:{"label":"硕士","value":"硕士"}}),_c('el-option',{attrs:{"label":"MBA","value":"MBA"}}),_c('el-option',{attrs:{"label":"博士","value":"博士"}})],1)],1),_c('el-form-item',{attrs:{"label":"职位描述","prop":"j_job_describe","rules":[
              {
                required: true,
                message: '请选择职位描述',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":3,"maxlength":"1000"},on:{"input":_vm.infoInput},model:{value:(_vm.postInfo.j_job_describe),callback:function ($$v) {_vm.$set(_vm.postInfo, "j_job_describe", $$v)},expression:"postInfo.j_job_describe"}}),_c('p',{staticClass:"text-right padding-top-5"},[_vm._v(" 您还可以输入"),_c('el-link',{attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.destextLength))]),_vm._v(" 字 ")],1)],1)],1)],1):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"formInfoTitle"},[_c('span',[_vm._v("职位基本信息")]),_c('span',[_vm._v("信息越完善，职位排序越靠前")])])}]

export { render, staticRenderFns }