<template>
  <!-- 兼职全职form -->
  <div>
    <section class="jobInfo">
      <p class="formInfoTitle">
        <span>职位基本信息</span>
        <span>信息越完善，职位排序越靠前</span>
      </p>
      <div class="infoBox">
        <el-form label-width="96px">
          <el-form-item
            label="职业性质"
            :rules="[
              {
                required: true,
                message: '请选择职业性质',
                trigger: 'change',
              },
            ]"
          >
            <el-radio
              v-model="flag"
              label="1"
              :disabled="qzdisable"
              @change="(jzShow = true), (qzShow = false)"
              >兼职</el-radio
            >
            <el-radio
              v-model="flag"
              label="2"
              :disabled="jzdisable"
              @change="(qzShow = true), (jzShow = false)"
              >全职</el-radio
            >
          </el-form-item>
        </el-form>
        <!-- 兼职 -->
        <div v-if="jzShow">
          <el-form label-width="96px" :model="jzpostInfo">
            <el-form-item
              label="需求证书"
              style="position:relative"
              prop="j_register"
              :rules="[
                {
                  required: true,
                  message: '请选择需求证书和注册情况',
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                style="width: 70%"
                v-model="cerList"
                placeholder="选择或搜索需求证书"
                :disabled="jzdisable"
                :options="selCerList"
                :props="{
                  checkStrictly: true,
                  value: 'certificate',
                  label: 'certificate',
                  children: 'child',
                }"
                filterable
                clearable
              >
              </el-cascader>
              <el-select
                v-model="jzpostInfo.j_register"
                placeholder="注册情况"
                style="width: 30%"
              >
                <el-option label="初始注册" value="初始注册"></el-option>
                <el-option label="转注册" value="转注册"></el-option>
                <el-option label="初转不限" value="初转不限"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="证书价格"
              prop="j_min_price"
              :rules="[
                {
                  required: true,
                  message: '请填写证书最小价格和最大价格',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input-number
                :min="0"
                :controls="false"
                v-model="jzpostInfo.j_min_price"
                placeholder="证书最小价格（单位：万元）"
                style="width:48%"
                @change="getPrice"
              ></el-input-number>
              <span style="color: #999"> — </span>
              <el-input-number
                :min="0"
                :controls="false"
                v-model="jzpostInfo.j_max_price"
                placeholder="证书最大价格（单位：万元）"
                style="width:48%"
                @change="getPrice"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="用证地区"
              :rules="[
                {
                  required: true,
                },
              ]"
            >
              <el-cascader
                size="large"
                :options="comcity"
                v-model="jzcity"
                @change="setjzcity"
                :placeholder="cityzwf"
                style="width: 100%;"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="证书用途"
              prop="j_purpose"
              :rules="[
                {
                  required: true,
                  message: '请选择证书用途',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_purpose" style="width: 100%">
                <el-option label="用于资质" value="用于资质"></el-option>
                <el-option label="用于项目" value="用于项目"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="社保要求"
              prop="j_social_need"
              :rules="[
                {
                  required: true,
                  message: '请选择社保要求',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_social_need" style="width: 100%">
                <el-option label="不限" value="不限"></el-option>
                <el-option label="唯一社保" value="唯一社保"></el-option>
                <el-option label="非唯一社保" value="非唯一社保"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="签约时长"
              prop="j_sign_time"
              :rules="[
                {
                  required: true,
                  message: '请选择签约时长',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_sign_time" style="width: 100%">
                <el-option label="1个月" value="1个月"></el-option>
                <el-option label="2个月" value="2个月"></el-option>
                <el-option label="3个月" value="3个月"></el-option>
                <el-option label="4个月" value="4个月"></el-option>
                <el-option label="5个月" value="5个月"></el-option>
                <el-option label="6个月" value="6个月"></el-option>
                <el-option label="7个月" value="7个月"></el-option>
                <el-option label="8个月" value="8个月"></el-option>
                <el-option label="9个月" value="9个月"></el-option>
                <el-option label="10个月" value="10个月"></el-option>
                <el-option label="11个月" value="11个月"></el-option>
                <el-option label="12个月" value="12个月"></el-option>
                <el-option label="2年" value="2年"></el-option>
                <el-option label="3年" value="3年"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="签约方式"
              prop="j_sign_method"
              :rules="[
                {
                  required: true,
                  message: '请选择签约方式',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_sign_method" style="width: 100%">
                <el-option label="企业直签" value="企业直签"></el-option>
                <el-option label="委托代签" value="委托代签"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="付款方式"
              prop="j_pay_method"
              :rules="[
                {
                  required: true,
                  message: '请选择签约方式',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_pay_method" style="width: 100%">
                <el-option label="定金加尾款" value="定金加尾款"></el-option>
                <el-option label="见证全款" value="见证全款"></el-option>
                <el-option label="公示全款" value="公示全款"></el-option>
                <el-option label="提交全款" value="提交全款"></el-option>
                <el-option
                  label="转网上关系全款"
                  value="转网上关系全款"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="签约状态"
              prop="j_sign_status"
              :rules="[
                {
                  required: true,
                  message: '请选择签约方式',
                  trigger: 'change',
                },
              ]"
            >
              <el-select v-model="jzpostInfo.j_sign_status" style="width: 100%">
                <el-option label="洽谈中" value="洽谈中"></el-option>
                <el-option label="已签约" value="已签约"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="职位描述"
              prop="j_job_agree"
              :rules="[
                {
                  required: true,
                  message: '请输入职位描述',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                :rows="3"
                v-model="jzpostInfo.j_job_agree"
                maxlength="1000"
                @input="infoInput1"
              ></el-input>
              <p class="text-right padding-top-5">
                您还可以输入<span class="font-blue">{{ destextLength1 }}</span>
                字
              </p>
            </el-form-item>
          </el-form>
        </div>
        <!-- 全职 -->
        <div v-if="qzShow">
          <el-form label-width="96px" :model="postInfo">
            <el-form-item
              label="需求证书"
              style="position:relative"
              prop="j_register"
              :rules="[
                {
                  required: true,
                  message: '请选择需求证书和注册情况',
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                style="width: 70%"
                v-model="cerList"
                placeholder="选择或搜索需求证书"
                :disabled="qzdisable"
                :options="selCerList"
                :props="{
                  checkStrictly: true,
                  value: 'certificate',
                  label: 'certificate',
                  children: 'child',
                }"
                filterable
                clearable
              >
              </el-cascader>
              <el-select
                v-model="postInfo.j_register"
                placeholder="注册情况"
                style="width: 30%"
              >
                <el-option label="初始注册" value="初始注册"></el-option>
                <el-option label="转注册" value="转注册"></el-option>
                <el-option label="初转不限" value="初转不限"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="requireLabel" label="职位类型">
              <el-cascader
                style="width: 100%"
                v-model="jobList"
                :options="selJobList"
                :props="{
                  checkStrictly: true,
                  value: 'category_name',
                  label: 'category_name',
                  children: 'child',
                }"
                filterable
                clearable
              >
              </el-cascader>
              <el-checkbox v-model="tjzw" class="margin-left-20"
                >选择系统推荐的职位</el-checkbox
              >
            </el-form-item>
            <el-form-item
              label="月薪"
              prop="j_max_price"
              :rules="[
                {
                  required: true,
                  message: '请填写最低月薪和最高月薪',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model.number="postInfo.j_min_price"
                placeholder="最低月薪（单位：千元）"
                style="width:48%"
              ></el-input>
              <span style="color: #999"> — </span>
              <el-input
                v-model.number="postInfo.j_max_price"
                placeholder="最高月薪（单位：千元）"
                style="width:48%"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="职位名称"
              prop="j_job_name"
              :rules="[
                {
                  required: true,
                  message: '请填写职位名称',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="postInfo.j_job_name"
                minlength="2"
                placeholder="请填写职位名称，2~30个字符"
              ></el-input>
              <span
                style="font-size: 12px;color:#f00;line-height: 16px;display: inline-block;"
              >
                （重要提示：根据相关法律法规，我们将对您提交的职位进行审核，通常不超过30分钟，职位名称中含有“挂资质”"社保"“挂项目”“挂靠”“挂证”“聘证”“寻证”等关键词禁止发布，通过后将自动发布）
              </span>
            </el-form-item>
            <el-form-item
              label="所在地区"
              prop="j_work_place"
              :rules="[
                {
                  required: true,
                  message: '请选择所在地区和详细街道门牌号',
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                size="large"
                :options="comcity"
                :placeholder="cityzwf"
                v-model="selectedOptions"
                @change="setUseInfoSocial"
                style="width: 48%;"
              >
              </el-cascader>
              <span style="color: #999"> — </span>
              <el-input
                v-model="postInfo.j_work_place"
                placeholder="街道门牌号"
                style="width:48%"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="职称类型"
              prop="j_job_need"
              :rules="[
                {
                  required: true,
                  message: '请选择职称类型和职称等级',
                  trigger: 'change',
                },
              ]"
            >
              <el-cascader
                style="width: 70%"
                v-model="postList"
                placeholder="选择或搜索职称类型"
                :options="selPostList"
                :props="{
                  checkStrictly: true,
                  value: 'title',
                  label: 'title',
                  children: 'child',
                }"
                filterable
                clearable
              >
              </el-cascader>
              <el-select
                v-model="postInfo.j_job_need"
                placeholder="职称等级"
                style="width: 30%"
              >
                <el-option label="不限" value="不限"></el-option>
                <el-option label="初级" value="初级"></el-option>
                <el-option label="中级" value="中级"></el-option>
                <el-option label="高级" value="高级"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="工作经验"
              prop="j_work_experience"
              :rules="[
                {
                  required: true,
                  message: '请选择工作经验',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="postInfo.j_work_experience"
                style="width: 100%"
              >
                <el-option label="1年内" value="1年内"></el-option>
                <el-option label="1-3年" value="1-3年"></el-option>
                <el-option label="3-5年" value="3-5年"></el-option>
                <el-option label="5-8年" value="5-8年"></el-option>
                <el-option label="8-10年" value="8-10年"></el-option>
                <el-option label="10年以上" value="10年以上"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="学历要求"
              prop="j_education_need"
              :rules="[
                {
                  required: true,
                  message: '请选择工作经验',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="postInfo.j_education_need"
                style="width: 100%"
              >
                <el-option label="高中" value="高中"></el-option>
                <el-option label="中技" value="中技"></el-option>
                <el-option label="中专" value="中专"></el-option>
                <el-option label="大专" value="大专"></el-option>
                <el-option label="本科" value="本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="MBA" value="MBA"></el-option>
                <el-option label="博士" value="博士"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="职位描述"
              prop="j_job_describe"
              :rules="[
                {
                  required: true,
                  message: '请选择职位描述',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                :rows="3"
                v-model="postInfo.j_job_describe"
                maxlength="1000"
                @input="infoInput"
              ></el-input>
              <p class="text-right padding-top-5">
                您还可以输入<el-link type="primary">{{
                  destextLength
                }}</el-link>
                字
              </p>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  name: "jobInfo",
  data() {
    return {
      flag: "",
      postInfo: {},
      jzpostInfo: {},
      cerList: [],
      jobList: [],
      postList: [],
      selCerList: [],
      selJobList: [],
      selPostList: [],
      selectedOptions: [],
      comcity: provinceAndCityData,
      jzcity: [],
      destextLength: 1000,
      destextLength1: 1000,
      tjzw: false,
      jzShow: true,
      qzShow: false,
      cityzwf: "",
      jzdisable: false,
      qzdisable: false,
    };
  },
  methods: {
    // 城市选择
    setUseInfoSocial() {
      this.postInfo.j_province = CodeToText[this.selectedOptions[0]];
      this.postInfo.j_city = CodeToText[this.selectedOptions[1]];
    },
    setjzcity() {
      this.jzpostInfo.j_area_province = CodeToText[this.jzcity[0]];
      this.jzpostInfo.j_area_city = CodeToText[this.jzcity[1]];
    },
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 职位下拉
    getJobList() {
      this.$http.post("index/certificate/postion").then((res) => {
        this.selJobList = res.data.resultData;
      });
    },
    // 职称下拉
    getPostList() {
      this.$http.post("index/certificate/zctitle").then((res) => {
        this.selPostList = res.data.resultData;
      });
    },
    // 描述字数限制
    infoInput() {
      this.destextLength = 1000 - this.postInfo.j_job_describe.length;
    },
    infoInput1() {
      this.destextLength1 = 1000 - this.jzpostInfo.j_job_agree.length;
    },
    // 证书价格限制
    getPrice() {
      if (this.jzpostInfo.j_min_price < 0 || this.jzpostInfo.j_min_price > 50) {
        this.$message.warning("证书最小价格不得低于0元，最大不得超过50万元");
        if (this.jzpostInfo.j_min_price > 50) {
          this.$set(this.jzpostInfo, "j_min_price", 50);
        }
      }
      if (this.jzpostInfo.j_max_price < 0 || this.jzpostInfo.j_max_price > 50) {
        this.$message.warning("证书最小价格不得低于0元，最大不得超过50万元");
        if (this.jzpostInfo.j_max_price > 50) {
          this.$set(this.jzpostInfo, "j_max_price", 50);
        }
      }
    },
  },
  mounted() {
    this.getCerList();
    this.getJobList();
    this.getPostList();
  },
};
</script>
<style scoped>
.formInfoTitle {
  padding: 40px;
}
.formInfoTitle span:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #2573f1;
  border-bottom: 2px solid #2573f1;
}
.formInfoTitle span:last-child {
  color: #999;
  padding: 0px 10px;
}
.infoBox {
  padding: 0px 200px;
}
</style>
