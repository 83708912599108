<template>
  <!-- 发布职位 -->
  <div class="contentBox">
    <stepTwo></stepTwo>
    <div style="height: 50px"></div>
    <jobInfo ref="jobInfo"></jobInfo>
    <section>
      <el-form label-width="96px">
        <p class="formInfoTitle">
          <span>企业基本信息</span>
          <span></span>
        </p>
        <div class="infoBox">
          <el-form-item label="企业名称">
            <el-input
              v-model="comRealeInfo.com_name"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="对外显示名称">
            <el-input
              v-model="comRealeInfo.com_external_name"
              disabled="disabled"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="text-center">
        <el-button
          type="primary"
          class="radius-25"
          style="width:200px"
          v-prevent-repeat-click
          @click="jobPost"
        >
          提交
        </el-button>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import stepTwo from "@/components/stepTwo/stepTwo";
import jobInfo from "@/view/comPage/postJob/jobInfo";
export default {
  components: {
    stepTwo,
    jobInfo,
  },
  computed: {
    ...mapState([]),
    comRealeInfo() {
      let k = sessionStorage.getItem("comRealeInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(sessionStorage.getItem("comRealeInfo"));
    },
    comStaffInfo() {
      let k = sessionStorage.getItem("comStaffInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(sessionStorage.getItem("comStaffInfo"));
    },
  },
  methods: {
    ...mapMutations(["changePostReturnInfo"]),
    jobPost() {
      // console.log(this.$refs.jobInfo.jzpostInfo.j_area_province);
      // if (
      //   !this.$refs.jobInfo.jzpostInfo.j_area_province ||
      //   !this.$refs.jobInfo.jzpostInfo.j_area_city
      // ) {
      //   this.$message.warning("用证地区为必填项！");
      // }
      let info = {};
      if (this.$refs.jobInfo.flag == 1) {
        // 兼职
        info = this.$refs.jobInfo.jzpostInfo;
        info.flag = "1";
        info.type = 1;
        info.com_id = this.comRealeInfo.id;
        if (this.$refs.jobInfo.cerList.length === 3) {
          info.j_certificate = this.$refs.jobInfo.cerList[0];
          info.j_major = this.$refs.jobInfo.cerList[1];
          info.j_leve = this.$refs.jobInfo.cerList[2];
        } else if (this.$refs.jobInfo.cerList.length === 2) {
          info.j_certificate = this.$refs.jobInfo.cerList[0];
          info.j_major = this.$refs.jobInfo.cerList[1];
          info.j_leve = "";
        }
      } else if (this.$refs.jobInfo.flag == 2) {
        // 全职
        info = this.$refs.jobInfo.postInfo;
        info.flag = "1";
        info.type = 2;
        info.com_id = this.comRealeInfo.id;
        console.log(this.$refs.jobInfo.cerList.length);
        if (this.$refs.jobInfo.cerList.length === 3) {
          info.j_certificate = this.$refs.jobInfo.cerList[0];
          info.j_major = this.$refs.jobInfo.cerList[1];
          info.j_leve = this.$refs.jobInfo.cerList[2];
        } else if (this.$refs.jobInfo.cerList.length === 2) {
          info.j_certificate = this.$refs.jobInfo.cerList[0];
          info.j_major = this.$refs.jobInfo.cerList[1];
          info.j_leve = "";
        }
        info.j_job_type = this.$refs.jobInfo.jobList[0];
        info.j_job_major = this.$refs.jobInfo.jobList[1];
        if (this.$refs.jobInfo.tjzw) {
          info.j_is_tj = 1;
        } else {
          info.j_is_tj = 0;
        }
        info.j_zc_type = this.$refs.jobInfo.postList[0];
        info.j_zc_major = this.$refs.jobInfo.postList[1];
      }
      this.$http.post("company/index/publish", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.changePostReturnInfo({
            postReturnInfo: res.data.resultData,
          });
          this.$router.push("postThree");
        }
      });
    },
  },
  mounted() {
    console.log(this.comRealeInfo);
  },
};
</script>
<style scoped>
.formInfoTitle {
  padding: 40px;
}
.formInfoTitle span:first-child {
  font-size: 17px;
  font-weight: bold;
  color: #2573f1;
  border-bottom: 2px solid #2573f1;
}
.formInfoTitle span:last-child {
  color: #999;
  padding: 0px 10px;
}
.infoBox {
  padding: 0px 200px;
}
</style>
